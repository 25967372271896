import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Link, Route } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';

import { joinClass } from '../../helpers/component';
import { reviewPaymentPlan } from '../../actions/collections';
import { setSubmit, setSuccess } from '../../actions/userMessages';
import { getPaymentInstruments } from '../../actions/instruments';
import { kibanaLog } from '../../helpers/logger';
import { flagEnabled } from '../../helpers/featureFlags';
import withStepUp from '../../helpers/withStepUp';

import {
  COLLECTIONS,
  COLLECTIONS_BANK,
  COLLECTIONS_CARD,
  COLLECTIONS_REVIEW,
} from '../../helpers/routes';
import { cpcDataToPaymentFormData } from '../../helpers/payments';

import AddBank from '../../components/AddBank';
import AddCard from '../../components/AddCard';

import useCpc from '../../hooks/useCpc';
import useSessionStorageCleanup from '../../hooks/useSessionStorageCleanup';

const AddInstrumentForm = ({
  submitting,
  errorMessage,
  initialValues,
  paymentFormData: paymentFormDataProp,
  history,
  handleReviewPaymentPlan,
  type,
  handleGetPaymentInstruments,
}) => {
  const cpc = useCpc();
  const paymentFormData = flagEnabled('stepUpEnabled')
    ? JSON.parse(sessionStorage.getItem('maw_paymentFormData')) || {}
    : paymentFormDataProp;

  useEffect(() => {
    if (!Object.keys(paymentFormData).length) {
      history.push(COLLECTIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSessionStorageCleanup('maw_paymentFormData');

  const onAddInstrumentSubmit = async () => {
    try {
      const cpcPromise = await cpc.submitForm();
      const { cpcData: { submissionDetails: { cpcStatus } } } = cpcPromise;
      if (cpcStatus === 'ERROR') {
        // CPC component is handling displaying error msg
        return;
      }
      // force a get instruments here so that if the user goes back
      // to /new by clicking back or clicking "Make Another Payment"
      // on the confirmation page they will see the instrument they
      // just added.
      kibanaLog('cpc_submit_success');
      handleGetPaymentInstruments({ force: true, showLoading: false });
      handleReviewPaymentPlan({
        ...paymentFormData,
        ...cpcDataToPaymentFormData(cpcPromise),
      });
      history.push(COLLECTIONS_REVIEW);
    } catch (e) {
    // CPC component will handle displaying error msg
    }
  };

  return (
    <PageSection>
      <Formik
        initialValues={initialValues}
        onSubmit={onAddInstrumentSubmit}
      >
        {({
          values,
          setFieldValue,
          errors,
          submitCount,
        }) => (
          <Form
            noValidate
          >
            <div className="payment-section">
              <>
                <Route
                  exact
                  path={COLLECTIONS_BANK}
                  render={() => (
                    <AddBank
                      values={values}
                      error={errorMessage}
                      canStoreInstruments
                      setFieldValue={setFieldValue}
                      displayStoredPaymentOption
                    />
                  )}
                />
                <Route
                  exact
                  path={COLLECTIONS_CARD}
                  render={() => (
                    <AddCard
                      values={values}
                      error={errorMessage}
                      canStoreInstruments
                      setFieldValue={setFieldValue}
                      displayStoredPaymentOption
                    />
                  )}
                />
              </>
            </div>

            <div className="action action--right pt6">
              <div className="action__item">
                <button
                  type="submit"
                  className={joinClass('button button--primary', submitting && 'is-loading')}
                  disabled={submitting}
                >
                  Continue
                </button>
              </div>
              <div className="action__item">
                <Link to={COLLECTIONS} className="button">Back</Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PageSection>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    instruments: { submitting },
    userMessages: {
      error: errorMessage,
      showModal,
    },
  } = state;

  const { location } = ownProps;

  const type = location.pathname === COLLECTIONS_BANK ? 'Bank' : 'PaymentCard';

  const initialValues = {};

  if (type === 'Bank') {
    initialValues.account = 'Checking';
  }

  return {
    submitting,
    type,
    initialValues: {
      ...initialValues,
      billingAddressCheckbox: false,
      savePayment: false,
      isDefault: 'no',
    },
    errorMessage: !showModal && errorMessage,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetPaymentInstruments: getPaymentInstruments,
  handleReviewPaymentPlan: reviewPaymentPlan,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStepUp(AddInstrumentForm));
