import React from 'react';
import { connect } from 'react-redux';
import getConfig from '../../../config';
import { isNowPage } from '../../../helpers/now';
import { flagEnabled } from '../../../helpers/featureFlags';

const { homeDomain } = getConfig();

const HIDE_BACK = { 'hide-back': true };
const CPC_PATHS = [
  '/bank',
  '/card',
  '/new-card',
  '/new-bank',
];
const HIDE_BACK_PATHS = [
  '/confirmation',
  '/cancel-confirmation',
  '/auth-required',
  '/no-services',
  '/nocontact-error',
  ...(flagEnabled('stepUpEnabled') ? CPC_PATHS : []),
];
const ADD_BACK_URL_PATHS = ['/new'];

const BACK_URL = `https://${homeDomain}/billing/services`;
const DISMISS_LANDING_URL = `https://${homeDomain}/now/billing/wallet`;

const { pathname } = window.location;
const isNow = isNowPage(pathname);

const shouldAddBackUrl = () => ADD_BACK_URL_PATHS
  .some(endPath => window.location.pathname === endPath);
const canGoBack = (isHarness, user) => (
  !isHarness
  && !(isNow && (user?.nowAvailable === false || user?.now?.available === false))
  && window.history.length > 1
  && !HIDE_BACK_PATHS.some(endPath => window.location.pathname.endsWith(endPath))
);


const PolarisHeader = ({ isHarness, user }) => (
  <xc-header
    client-id="myAccountWeb"
    flownav
    auto-auth="true"
    enable-account-selector="1"
    {...(!canGoBack(isHarness, user) ? HIDE_BACK : {})}
    {...(shouldAddBackUrl() && { 'back-url': BACK_URL })}
    {...(isNow && (user?.nowAvailable || user?.now?.available) && { 'show-dismiss': true })}
    dismiss-landing-url={DISMISS_LANDING_URL}
  />
);


const mapStateToProps = ({
  harness: { isHarness },
  user: { user },
}) => ({ isHarness, user });

export default connect(mapStateToProps, null)(PolarisHeader);
